<template>
  <div v-if="!$store.state.user"></div>
  <div v-else class="body-container">
    <router-view />
  </div>
</template>

<script>
import { watchEffect } from 'vue';
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();

    watchEffect(async () => {
      try {
        const { user } = await window.tysso.getProfile();

        store.commit('getUser', {
          userId: user.id,
          memberId: +user._json.oid.split('/')[1],
          cardNo: user._json.cardNo,
          merchantId: user.merchantId || process.env.VUE_APP_MERCHANT_ID,
        });
      } catch (err) {
        console.error(err);
      }
    });
  },
};
</script>
