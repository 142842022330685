import axios from 'axios';
const apiHost = process.env.VUE_APP_API_HOST;

export function getCampaignInfo(user) {
  var config = {
    method: 'get',
    url: `${apiHost}/apis/v1/merchants/${user.merchantId}/campaign`,
  };
  return axios(config);
}

export function getSocialWelfareList(user) {
  var config = {
    method: 'get',
    url: `${apiHost}/apis/v1/merchants/${user.merchantId}/lovecodes`,
  };
  return axios(config);
}

export function getUserInvoices(user, data) {
  const { userId, merchantId } = user;
  var config = {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    url: `${apiHost}/apis/v1/merchants/${merchantId}/members/${userId}/carrier-invoices`,
    data,
  };
  return axios(config);
}

export function getRecords(user) {
  const { userId, merchantId, memberId } = user;
  var config = {
    method: 'get',
    url: `${apiHost}/apis/v1/merchants/${merchantId}/members/${userId}/donate-records`,
    params: { memberId },
  };
  return axios(config);
}

export function getRecord(user, recordId) {
  const { userId, merchantId } = user;
  var config = {
    method: 'get',
    url: `${apiHost}/apis/v1/merchants/${merchantId}/members/${userId}/donate-records/${recordId}`,
  };
  return axios(config);
}

export function donateInvoices(user, data) {
  const { userId, merchantId } = user;
  var config = {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    url: `${apiHost}/apis/v1/merchants/${merchantId}/members/${userId}/donate`,
    data,
  };
  return axios(config);
}
