<template>
  <LoadingSpinner v-show="isLoading" />
  <section>
    <div class="top-bar">
      <div class="nav">
        <a @click="$router.push('/')"><i class="fa-solid fa-angle-left"></i></a>
      </div>
      <h2>捐發票換點數</h2>
      <div class="nav right">
        <a :href="portalUrl"><i class="icon-close fa-solid fa-xmark"></i></a>
      </div>
    </div>
  </section>
  <div class="main-container">
    <div class="title-bar">
      <b>捐贈紀錄</b>
      <a @click="loadRecords"><i class="fa-solid fa-arrows-rotate"></i></a>
    </div>

    <a
      class="popup record-list"
      v-for="record in records"
      :key="record.requestId"
      @click="showDetail(record.requestId)"
    >
      <div class="left">
        <div>{{ record.socialWelfareName }}</div>
        <time>{{ displayTsToDateTime(record.createdAt) }}</time>
      </div>
      <div v-if="record.status === 'processing'" class="mid">
        <div>處理中</div>
      </div>
      <div v-else class="mid">
        <div>{{ displayRewardStatus(record) }}</div>
        <div>捐贈發票 x{{ record.rewardAmount }}</div>
      </div>
      <i class="fa-solid fa-angle-right"></i>
    </a>
  </div>
  <DonationDetail
    v-if="modalOpen"
    @closeModal="modalOpen = false"
    :record="record"
  />
  <ErrorPopup
    v-if="errorModalOpen"
    @closeModal="errorModalOpen = false"
    :content="popupContent"
  />
</template>

<script>
import { reactive, toRefs, watchEffect } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import DonationDetail from '@/components/DonationDetail.vue';
import ErrorPopup from '@/components/ErrorPopup.vue';
import { getRecords, getRecord } from '@/apis/fetchData';

export default {
  name: 'Records',
  components: { LoadingSpinner, DonationDetail, ErrorPopup },
  setup() {
    const store = useStore();
    const state = reactive({
      records: [],
      record: null,
      isLoading: false,
      modalOpen: false,
      portalUrl: process.env.VUE_APP_PORTAL_URL,
      errorModalOpen: false,
      popupContent: {
        msg: '',
        subMsg: '',
        redirectTo: '',
      },
    });

    const displayTsToDateTime = ts =>
      moment(ts * 1000).format('YYYY-MM-DD HH:mm');

    const displayRewardStatus = record => {
      if (
        record.rewardStatus === 'success' ||
        record.rewardStatus === 'cancelled'
      )
        return `${record.rewardDesc} x${record.rewardAmount}`;
      if (record.rewardStatus === 'insufficient') return '獎勵已發完';
      return '處理中';
    };

    const loadRecords = async () => {
      const { user } = store.state;
      if (!user?.userId || !user?.merchantId || !user?.memberId) return;

      try {
        state.isLoading = true;
        const {
          data: { details: records },
        } = await getRecords(store.state.user);

        state.records = records;
        state.isLoading = false;
      } catch (err) {
        if (err.response?.status == 401) {
          loadRecords();
        } else {
          state.popupContent = {
            msg: '網站錯誤',
            subMsg: '無法取得捐贈紀錄資料，請稍後再試',
          };
          state.errorModalOpen = true;
        }
        state.isLoading = false;
        console.error(err);
      }
    };

    const showDetail = async recordId => {
      try {
        state.isLoading = true;
        const { data: record } = await getRecord(store.state.user, recordId);

        state.record = record;
        state.isLoading = false;
        state.modalOpen = true;
      } catch (err) {
        state.isLoading = false;
        state.modalOpen = true;
        console.error(err);
      }
    };

    watchEffect(() => {
      const { user } = store.state;
      if (!user) return;
      loadRecords();
    });

    return {
      ...toRefs(state),
      loadRecords,
      showDetail,
      displayTsToDateTime,
      displayRewardStatus,
    };
  },
};
</script>
