<template>
  <section>
    <div class="top-bar">
      <div class="nav">
        <a @click="$router.push('/records')">捐贈紀錄</a>
      </div>
      <h2>捐發票換點數</h2>
      <div class="nav right">
        <a :href="portalUrl"><i class="icon-close fa-solid fa-xmark"></i></a>
      </div>
    </div>
  </section>
  <div class="main-container">
    <div class="top-cover"><img src="images/top-cover.jpg" /></div>
    <div
      v-if="isInitialized && !$store.state.campaignInfo"
      class="main-content"
    >
      <h1 align="center">目前無捐發票換點數活動</h1>
    </div>
    <div v-if="$store.state.campaignInfo" class="main-content">
      <Campaign :info="$store.state.campaignInfo" />
      <Donee />
      <InvoiceCarrier />
    </div>
  </div>
</template>

<script>
import { toRefs, watchEffect, reactive } from 'vue';
import { useStore } from 'vuex';
import Campaign from '@/components/Campaign.vue';
import Donee from '@/components/Donee.vue';
import InvoiceCarrier from '@/components/InvoiceCarrier.vue';
import { getCampaignInfo, getSocialWelfareList } from '@/apis/fetchData';

export default {
  name: 'Home',
  components: {
    Campaign,
    InvoiceCarrier,
    Donee,
  },
  setup() {
    const store = useStore();

    const state = reactive({
      portalUrl: process.env.VUE_APP_PORTAL_URL,
      isInitialized: false,
    });

    watchEffect(async () => {
      try {
        const { user } = store.state;
        if (!user?.merchantId) return;
        const { data: campaignData } = await getCampaignInfo(user);

        store.commit('getCampaignInfo', campaignData);
      } catch (err) {
        console.log(err);
      } finally {
        state.isInitialized = true;
      }
    });

    watchEffect(async () => {
      const { user, socialWelfareList } = store.state;
      if (!user?.merchantId || socialWelfareList.length) return;
      try {
        const { data: socialWelfareData } = await getSocialWelfareList(user);
        store.commit('getSocialWelfareList', socialWelfareData);
      } catch (err) {
        console.log(err);
      }
    });

    return { ...toRefs(state) };
  },
};
</script>
