import i18n from '@/plugins/i18n';

const state = () => ({
  lang: 'zh-tw', // 存放使用者選用的語系
});

// getters
const getters = {
  getLang: state => {
    return state.lang;
  },
};

// mutations
const mutations = {
  setLang(state, { language }) {
    state.lang = language;
    localStorage.setItem('locale', language);
    i18n.global.locale = language;
  },
};

// actions
const actions = {};

const language = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};

export default language;
