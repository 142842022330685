<template>
  <div v-if="needCardEncrypt" class="form-list">
    <b
      ><i>手機載具</i><br />
      <span class="carrier">{{ $store.state.user?.cardNo }}</span>
    </b>
    <span>
      <input type="password" placeholder="輸入驗證碼" v-model="cardEncrypt" />
    </span>
  </div>
  <div v-else class="form-list">
    <b>手機載具</b>
    <span>
      <span class="carrier">{{ $store.state.user?.cardNo }}</span>
    </span>
  </div>
  <div v-if="needCardEncrypt" class="form-msg">
    <div class="left">請重新輸入驗證碼</div>
    <div class="right">
      <a href="https://www.einvoice.nat.gov.tw/APCONSUMER/BTC511W/"
        >忘記驗證碼</a
      >
    </div>
  </div>
  <LoadingSpinner v-show="isLoading" />
  <InvoiceList
    v-if="!isLoading && showInvoiceList"
    :userInvoices="userInvoices"
    @setDonationInvoices="setDonationInvoices"
  />
  <a
    v-if="needCardEncrypt"
    @click="submitCardEncrypt"
    :class="['popup', 'bottom-btn', { disable: !cardEncrypt || isLoading }]"
    >送出驗證碼</a
  >
  <a
    v-else
    @click="handleClickDonateBtn"
    :class="[
      'popup',
      'bottom-btn',
      { disable: !donationInvoices.length || isLoading },
    ]"
    >{{ buttonText }}<br />
    <span v-if="donationInvoices.length == DONATION_LIMIT">{{
      `(已達單次捐贈張數上限)`
    }}</span>
  </a>

  <SuccessPopup
    v-if="successModalOpen"
    @closeModal="closeModal"
    :content="popupContent"
  />
  <ErrorPopup
    v-if="errorModalOpen"
    @closeModal="closeModal"
    :content="popupContent"
  />
  <ConfirmPopup
    v-if="confirmModalOpen"
    @closeModal="closeModal"
    @clickConfirmBtn="submitDonation"
    :content="popupContent"
  />
  <SocialWelfareListPopup
    v-if="welfareListModalOpen"
    @closeModal="closeModal"
    @clickDonateBtn="handleClickDonateBtn"
  />
</template>

<script>
import { reactive, watchEffect, toRefs, computed } from 'vue';
import { useStore } from 'vuex';
import InvoiceList from './InvoiceList.vue';
import SuccessPopup from './SuccessPopup.vue';
import ErrorPopup from './ErrorPopup.vue';
import ConfirmPopup from './ConfirmPopup.vue';
import SocialWelfareListPopup from './SocialWelfareListPopup.vue';
import LoadingSpinner from './LoadingSpinner.vue';
import { getUserInvoices, donateInvoices } from '@/apis/fetchData';

export default {
  name: 'InvoiceCarrier',
  components: {
    InvoiceList,
    SuccessPopup,
    ErrorPopup,
    ConfirmPopup,
    SocialWelfareListPopup,
    LoadingSpinner,
  },
  setup() {
    const DONATION_LIMIT = 10;
    const store = useStore();
    const state = reactive({
      userInvoices: null,
      donationInvoices: [],
      needCardEncrypt: false,
      cardEncrypt: '',
      successModalOpen: false,
      errorModalOpen: false,
      confirmModalOpen: false,
      welfareListModalOpen: false,
      popupContent: {
        msg: '',
        subMsg: '',
        redirectTo: '',
      },
      isLoading: false,
      showInvoiceList: false,
    });

    const buttonText = computed(() => {
      return !store.state.donee
        ? '請選擇捐贈單位'
        : '捐贈發票 x' + state.donationInvoices.length;
    });

    const closeModal = () => {
      state.successModalOpen = false;
      state.errorModalOpen = false;
      state.confirmModalOpen = false;
      state.welfareListModalOpen = false;
    };

    watchEffect(async () => {
      try {
        const { user } = store.state;
        if (!user) return;
        if (!user.cardNo || !user.merchantId) {
          state.popupContent = {
            msg: '手機載具未綁定成功',
            subMsg: '請至會員中心綁定或請洽客服人員',
          };
          state.errorModalOpen = true;
          return;
        }
        state.isLoading = true;
        const { data: userInvoices } = await getUserInvoices(user, {
          cardNo: user.cardNo,
        });
        state.userInvoices = userInvoices;
        state.isLoading = false;
        state.showInvoiceList = true;
      } catch (err) {
        state.isLoading = false;
        const error = err.response?.data?.error;
        console.log(err);

        if (error?.message === '參數驗證碼錯誤' || error?.code === 919) {
          state.needCardEncrypt = true;
        } else {
          state.needCardEncrypt = false;
          state.showInvoiceList = true;
        }
      }
    });

    const submitCardEncrypt = async () => {
      try {
        const { user } = store.state;
        if (!user?.cardNo || !user?.merchantId) {
          state.popupContent = {
            msg: '手機載具未綁定成功',
            subMsg: '請至會員中心綁定或請洽客服人員',
          };
          state.errorModalOpen = true;
          return;
        }
        if (!state.cardEncrypt || state.isLoading) return;

        state.isLoading = true;

        const { data: userInvoices } = await getUserInvoices(user, {
          cardNo: user.cardNo,
          cardEncrypt: state.cardEncrypt,
        });

        state.userInvoices = userInvoices;
        state.popupContent = {
          msg: '載具驗證成功',
        };
        state.isLoading = false;
        state.successModalOpen = true;
        state.needCardEncrypt = false;
        state.showInvoiceList = true;
      } catch (err) {
        console.log(err);
        const error = err.response?.data?.error;

        let message;
        if (error?.message === '參數驗證碼錯誤' || error?.code === 919) {
          message = '驗證碼錯誤';
          state.cardEncrypt = '';
        } else {
          message = '財政部網路忙碌中，請稍後再試';
        }
        state.popupContent = { msg: message };
        state.isLoading = false;
        state.errorModalOpen = true;
      }
    };

    const handleClickDonateBtn = () => {
      if (!store.state.donee) return (state.welfareListModalOpen = true);
      if (!state.donationInvoices.length || state.isLoading) return;

      if (state.donationInvoices.length > DONATION_LIMIT) {
        state.popupContent = { msg: `一次捐贈限${DONATION_LIMIT}張以內` };
        state.errorModalOpen = true;
        return;
      }

      state.popupContent = {
        msg: `確定捐贈${state.donationInvoices.length}張發票？`,
        subMsg: '一經捐贈將無法取消',
      };
      state.confirmModalOpen = true;
    };

    const submitDonation = async () => {
      try {
        closeModal();
        state.isLoading = true;

        const invoices = state.donationInvoices.map(
          ({
            formattedDate,
            invNum,
            amount,
            sellerBan,
            sellerName,
            sellerAddress,
            invPeriod,
          }) => ({
            invDate: formattedDate,
            invNum,
            invAmount: amount,
            sellerBan,
            sellerName,
            sellerAddress,
            invPeriod,
          })
        );
        const { user, campaignInfo, donee } = store.state;
        const postData = {
          memberId: user.memberId,
          cardNo: user.cardNo,
          welfareId: donee.id,
          campaignId: campaignInfo.id,
          invoices,
        };
        const res = await donateInvoices(user, postData);
        if (res.data?.status === 'cancelled') {
          state.popupContent = {
            msg: '發票捐贈失敗',
            subMsg:
              '可能原因如下：網路連線問題、該發票已被捐贈或具買方統編、同一賣家發票當日已捐贈超過3張。請確認發票狀態或稍後重試。',
            redirectTo: 'Records',
          };
          state.isLoading = false;
          closeModal();
          state.errorModalOpen = true;
          return;
        }

        state.popupContent = {
          msg: '發票捐贈成功',
          subMsg: '感謝您的參與',
          redirectTo: 'Records',
        };
        state.isLoading = false;
        state.successModalOpen = true;
      } catch (err) {
        const errorCode =
          err.response?.data?.error?.code || err.response?.status || '';

        let subMsg = '';
        console.log(errorCode, err);

        switch (errorCode) {
          case 'INV001':
            subMsg = '目前無捐發票換點數活動';
            break;
          case 'INV003':
            subMsg = '查無此捐贈單位愛心碼';
            break;
          case 'INV002':
          case 'INV004':
          case 'INV005':
            subMsg = err.response.data.error.message;
            break;
          default:
            subMsg = `網路錯誤${errorCode ? `[${errorCode}]` : ''}，請稍後再試`;
            break;
        }

        state.popupContent = {
          msg: '發票捐贈失敗',
          subMsg,
        };
        state.isLoading = false;
        closeModal();
        state.errorModalOpen = true;
      }
    };

    const setDonationInvoices = value => {
      state.donationInvoices = value;
    };

    return {
      ...toRefs(state),
      DONATION_LIMIT,
      buttonText,
      closeModal,
      submitCardEncrypt,
      handleClickDonateBtn,
      submitDonation,
      setDonationInvoices,
    };
  },
};
</script>
