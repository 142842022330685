import { createStore } from 'vuex';
import mutations from './mutations';
import getters from './getters';
import actions from './actions';
import state from './state';
import ModuleLanguage from './modules/language';

export default createStore({
  state,
  mutations,
  actions,
  getters,
  modules: {
    lang: ModuleLanguage,
  },
});
// export default createStore({
//   state: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })
