import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Records from '../views/Records.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/records',
    name: 'Records',
    component: Records,
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!window.tysso.isLoggedIn()) {
    window.tysso.login({
      redirectUrl: `${process.env.VUE_APP_HOST}${to.path}`,
    });
  } else {
    next();
  }
});

export default router;
