<template>
  <teleport to="body">
    <div class="popup-theme">
      <div class="popup-container">
        <div class="overlay-bg-x" @click="$emit('closeModal')"></div>
        <div class="popup-content" @click.stop>
          <div class="big-icon orange">
            <i class="fa-solid fa-exclamation"></i>
          </div>
          <h3 align="center">{{ content.msg }}</h3>
          <h4 v-if="content.subMsg" align="center">{{ content.subMsg }}</h4>
          <p><br /></p>

          <div class="btns">
            <a class="popup popup-close white-btn" @click="$emit('closeModal')"
              >取消</a
            >
            <a class="popup" @click="$emit('clickConfirmBtn')">確定</a>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { onMounted, onBeforeUnmount } from 'vue';

export default {
  name: 'ConfirmPopup',
  props: ['content'],
  emits: ['closeModal', 'clickConfirmBtn'],
  setup() {
    onMounted(() => {
      document.body.style.overflow = 'hidden';
    });

    onBeforeUnmount(() => {
      document.body.style.overflow = 'scroll';
    });
  },
};
</script>
